import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { ToastrModule } from 'ngx-toastr';
import { ToggleMidasComponent } from './toggle-midas.component';

@NgModule({
    declarations: [ToggleMidasComponent],
    imports: [CommonModule, FontAwesomeModule, HiramCommonsModule, ToastrModule.forRoot()],
    exports: [ToggleMidasComponent],
})
export class ToggleMidasModule {}
