import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, throwError } from 'rxjs';
import { MaterialHmisService } from 'src/app/core/services/material-hmis/material-hmis.service';
import { tap, catchError, map, switchMap } from 'rxjs/operators';
import { MaterialHMIS } from 'src/app/core/model/materialHMIS';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { HiramMaterialHMISValueItem } from 'src/app/core/model/hiram-materialHMIS-value-item';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HazardStatement } from 'src/app/core/model/hazard-statement';
import { MidasService } from 'src/app/core/services/midas.service';
import { CustomConfigurationService } from 'src/app/core/services/custom-configuration.service';
import { MidasContract } from 'src/app/core/model/midas-contract';

// tslint:disable:max-line-length

@Component({
    selector: 'hiram-material-hmis-add',
    templateUrl: './material-hmis-add.component.html',
    styleUrls: ['./material-hmis-add.component.scss'],
})
export class MaterialHmisAddComponent implements OnInit {
    @Output() OnProductAdded: Subject<HiramMaterialHMISValueItem>;

    @ViewChild('searchTextbox', { static: false })
    searchTextbox;

    public hiramId: number;
    public products: MaterialHMIS[];
    public searchProductForm: UntypedFormGroup;
    public searching = false;
    private selecting = false;
    public errorMessage = false;
    private chemIdRegex = new RegExp('^([0-9]+-[0-9]+-[0-9]+)');
    private useNewMidas = false;
    public message: string;

    constructor(
        public bsModalRef: BsModalRef,
        private service: MaterialHmisService,
        private hiramService: HiramService,
        private fb: UntypedFormBuilder,
        private midasService: MidasService,
        private customConfigurationService: CustomConfigurationService
    ) { }

    ngOnInit() {
        this.searchProductForm = this.buildSearchProductForm();
        this.OnProductAdded = new Subject();
        this.customConfigurationService.get().subscribe(v => {
            this.useNewMidas = v.value == 'true';
        })

        setTimeout(() => this.searchTextbox.nativeElement.focus());
    }

    searchProduct() {
        const values = this.searchProductForm.value;
        this.searching = true;
        this.errorMessage = false;

        if (this.useNewMidas) {
            this.midasService.get(values.searchString)
            .pipe(
                tap(() => (this.searching = false)),
                map(items => items.result),
                map(items => this.mapOldMidasToNewMidas(items)),
                catchError(error => this.handleMidasSearchError(error))
            )
            .subscribe(items => (this.products = items));

        } else {
            this.service
                .find(values.searchString)
                .pipe(
                    tap(() => (this.searching = false)),
                    map(items => items.map(item => {
                        return {
                            ...item,
                            chronicToxicity: item.chronicToxicity.toString() == '' ? 0 : 1,
                        };
                    })),
                    map(items => this.filterMidasChemicalsByBeginWith(items, values.searchString)),
                    catchError(error => this.handleMidasSearchError(error))
                )
                .subscribe(items => (this.products = items));
        }
    }

    filterMidasChemicalsByBeginWith(items: MaterialHMIS[], searchString: string) {
        const validItems = this.validMidasChemicalsBegginingWithSearchTerm(items, searchString);
        const invalidItemsSortedBySearchTerm = this.sortInvalidMidasChemicalsBySearchTerm(items, searchString);

        return validItems.concat(invalidItemsSortedBySearchTerm);
    }

    validMidasChemicalsBegginingWithSearchTerm(items: MaterialHMIS[], searchString: string) {
        const validItems = items.filter(item => this.chemIdRegex.test(item.casNumber));
        return validItems.sort(sortBySearchTermAndTitle(searchString));
    }

    sortInvalidMidasChemicalsBySearchTerm(items: MaterialHMIS[], searchString: string) {
        const itemsWithInvalidChemIdRegex = items.filter(item => !this.chemIdRegex.test(item.casNumber));
        const invalidChemicalsBegginingWithSearchTerm = itemsWithInvalidChemIdRegex.filter(item =>
            item.title.toUpperCase().startsWith(searchString.toUpperCase())
        );
        const otherInvalidChemicals = itemsWithInvalidChemIdRegex.filter(
            item => !item.title.toUpperCase().startsWith(searchString.toUpperCase())
        );
        return invalidChemicalsBegginingWithSearchTerm.concat(otherInvalidChemicals);
    }

    handleMidasSearchError(error) {
        let partial = this.useNewMidas ? 'Midas3 app' : 'Midas database';
        this.message = `Hiram was not able to reach the ${partial} for chemicals data`;
        this.searching = false;
        this.errorMessage = true;
        return throwError(this.message);
    }

    selectProduct(item: MaterialHMIS) {
        if (this.selecting) {
            return;
        }

        this.selecting = true;
        
        if (this.useNewMidas) {
            this.hiramService
                .addMaterialHMIS(this.hiramId, item)
                .pipe(
                    map(newMaterialHMIS => {
                        newMaterialHMIS.hazardStatement = item.hazardStatement;
                        return newMaterialHMIS;
                    }),
                    map(newMaterialHMIS => this.OnProductAdded.next(newMaterialHMIS))
                )
                .subscribe(_ => this.close());        
        } else {
            this.service
            .getHazardStatements(item.casNumber)
            .pipe(
                map(
                    newHazardStatement => (item.hazardStatement = newHazardStatement[0] ? newHazardStatement[0] : null)
                ),
                map(newHazardStatement => this.updateHazardStatementForDifferentDto(newHazardStatement)),
                switchMap(() =>
                    this.hiramService.addMaterialHMIS(this.hiramId, item).pipe(
                        map(newMaterialHMIS => {
                            newMaterialHMIS.hazardStatement = item.hazardStatement;
                            return newMaterialHMIS;
                        }),
                        map(newMaterialHMIS => this.OnProductAdded.next(newMaterialHMIS))
                    )
                )
            )
            .subscribe(_ => this.close());
        }
    }

    close() {
        this.bsModalRef.hide();
    }


    private updateHazardStatementForDifferentDto(hazardStatement: HazardStatement) {
        if (hazardStatement) {
            hazardStatement.statements = hazardStatement.hazardStatement ? hazardStatement.hazardStatement : null;  // socorro
        }
        return hazardStatement;
    }

    private buildSearchProductForm() {
        return this.fb.group({
            searchField: ['Chemical Name', Validators.required],
            searchString: [null, Validators.required],
        });
    }

    phsShortName(phsValue: number) {
        return this.service.phsShortName(phsValue);
    }

    phsLongName(phsValue: number) {
        return this.service.phsLongName(phsValue);
    }

    private mapOldMidasToNewMidas(items: MidasContract[]): MaterialHMIS[] {        
        return items.map(item => {
            var flashPointF = item.flashPointC ? Number.parseInt(item.flashPointC) : null;
            
            var hazardStatement = item.psimsid ? {
                chemId: item.chemID,
                dgn: item.psimsid.toString(),
                hazardStatement: item.hazardStatement,
                chemIdType: item.chemIDCategoryName,
                isHazardous: true,
                materialName: item.substanceName,
                pictograms: item.pictograms,
                precautionaryText: item.precautionaryText,
                tradeName: '', //TODO
                validFlag: item.isValidated ? 'T' : 'F',
                docStatusCode: item.docStatusCode,
                flashPointF: flashPointF,
                flashPointMethod: item.flashPointMethod
            } as HazardStatement: null;                                    
            return {
                title: item.substanceName,
                casNumber: item.chemID,
                categoryOnePhs: item.isPHSCat1 ? 1 : 0,
                categoryTwoPhs: item.isPHSCat2 ? 1 : 0,
                categoryThreePhs: item.isPHSCat3 ? 1 : 0,
                chronicToxicity: item.isChronic ? 1 : 0,
                health: item.nfpah,
                flammability: item.nfpaf,
                reactivity: item.nfpaf,
                hazardStatement: hazardStatement
             } as MaterialHMIS
        });
    }
}

function sortBySearchTermAndTitle(searchString: string) {
    return (a: MaterialHMIS, b: MaterialHMIS) => {
        const title1 = (a.title + '').toUpperCase();
        const title2 = (b.title + '').toUpperCase();
        const term = (searchString + '').toUpperCase();

        if (title1.startsWith(term) && title2.startsWith(term)) {
            return title1.localeCompare(title2);
        }

        if (title1.startsWith(term)) {
            return -1;
        }

        if (title2.startsWith(term)) {
            return 1;
        }

        const titleCompare = title1.localeCompare(title2);
        return titleCompare !== 0 ? titleCompare : (a.casNumber + '').localeCompare(b.casNumber + '');
    };
}
