import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CustomConfiguration, CustomConfigurationService } from 'src/app/core/services/custom-configuration.service';

@Component({
    selector: 'toggle-midas',
    templateUrl: './toggle-midas.component.html',
    styleUrls: ['./toggle-midas.component.sass'],
})
export class ToggleMidasComponent implements OnInit {
    response: string = "----"
    isUsing: boolean = false;
    isSaving: boolean = false;
    
    constructor(
        private customConfigurationService: CustomConfigurationService,
        private toastrService: ToastrService) {}

    ngOnInit() {
        this.customConfigurationService.get().subscribe(v => {
            this.isUsing = v.value === 'true';
            this.setResponse();
        })
    }

    submit() {        
        var entity = {
            key: "UseNewMidas",
            value: this.isUsing ? 'false' : 'true'
        } as CustomConfiguration;

        this.isSaving = true;

        this.customConfigurationService.save(entity)
            .subscribe(v => {
                this.isSaving = false;
                this.toastrService.success('Toggle updated succesfully');
                this.isUsing = !this.isUsing;
                this.setResponse();
            })
    }

    private setResponse() {
        this.response = this.isUsing ? 'Yes' : 'No';
    }
}
