import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { HeaderComponent } from './header/header.component';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangelogComponent } from './components/changelog/changelog.component';
import { HiramDetailsModule } from './components/hiram-details/hiram-details.module';
import { HiramCommonsModule } from './components/hiram-commons/hiram-commons.module';
import { ReportsModule } from './components/reports/reports.module';
import { HiramListModule } from './components/hiram-list/hiram-list.module';
import { ProcessPhysicalHazardItemsModule } from './components/admin/process-physical-hazard-items/process-physical-hazard-items.module';
import { IconModule } from './icon.module';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { ApprovalsModule } from './components/approvals/approvals.module';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { OtherHazardsModule } from './components/admin/other-hazards/other-hazards.module';
import { ApprovalRolesMembersModule } from './components/admin/approval-roles-members/approval-roles-members.module';
import { AppConfigService } from './core/services/app-config/app-config.service';
import { HttpXsrfInterceptor } from './core/services/authentication/xsrf-injector';
import { MarkdownModule } from 'ngx-markdown';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ReplacePersonModule } from './components/admin/replace-person/replace-person.module';
import { DocumentsModule } from './components/documents/documents.module';
import { SbraTicklerModule } from './components/admin/sbra-tickler/sbra-tickler.module';
import { UnsupportedBrowserPageComponent } from './components/unsupported-browser-page/unsupported-browser-page.component';
import { StandardDocumentsModule } from './components/admin/standard-documents/standard-documents.module';
import { ContactUsComponent } from './header/contact-us/contact-us.component';
import { ErrorComponent } from './components/error/error.component';
import { AdministratorsModule } from './components/admin/administrators-module/administrators.module';
import { UrlsModule } from './components/admin/urls/urls.module';
import { HelpComponent } from './components/help/help.component';
import { SplashScreenComponent } from './components/admin/splash-screen/splash-screen.component';
import { SplashScreenMessageComponent } from './components/splash-screen-message/splash-screen-message.component';
import { EnablePersonComponent } from './components/admin/enable-person/enable-person.component';
import { HfrCutPointsModule } from './components/admin/hfr-cut-points/hfr-cut-points.module';
import { XlsReportComponent } from './components/admin/xls-report/xls-report.component';
import { OtherInfoQuestionsModule } from './components/admin/other-info-questions/other-info-questions.module';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { AppMsalModule } from './core/services/authentication/app-msal.module';
import { ToggleMidasModule } from './components/admin/toggle-midas/toggle-midas.module';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        ContactUsComponent,
        ChangelogComponent,
        ErrorComponent,
        HelpComponent,
        EnablePersonComponent,
        BreadcrumbComponent,
        SplashScreenComponent,
        XlsReportComponent,
        SplashScreenMessageComponent,
        UnsupportedBrowserPageComponent,
    ],
    imports: [
        ApprovalsModule,
        DashboardModule,
        HiramListModule,
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        NgxFileDropModule,
        ReplacePersonModule,
        AppMsalModule,
        SbraTicklerModule,
        UrlsModule,
        DocumentsModule,
        HfrCutPointsModule,
        IconModule,
        MarkdownModule.forRoot({ loader: HttpClient }),
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HiramDetailsModule,
        HiramCommonsModule,
        OtherHazardsModule,
        StandardDocumentsModule,
        ProcessPhysicalHazardItemsModule,
        ApprovalRolesMembersModule,
        ReportsModule,
        AdministratorsModule,
        TooltipModule.forRoot(),
        AlertModule.forRoot(),
        ToastrModule.forRoot(),
        OtherInfoQuestionsModule,
        ToggleMidasModule
    ],
    providers: [
        AppConfigService,
        { provide: APP_INITIALIZER, useFactory: loadConfigService, deps: [AppConfigService], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptor, multi: true },
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
    constructor() {}
}

export function loadConfigService(configService: AppConfigService): () => void {
    return () => configService.load().toPromise();
}
