import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CustomConfigurationService {
    private defaultHttpParams = {
        withCredentials: true,
    };
    
    constructor(private httpClient: HttpClient) {}

    get(): Observable<CustomConfiguration> {                
        return this.httpClient.get<CustomConfiguration>(`${environment.baseUrl}/api/CustomConfiguration?type=UseNewMidas`, this.defaultHttpParams);
    }

    save(cc: CustomConfiguration): Observable<any> {
        return this.httpClient.put<CustomConfiguration>(`${environment.baseUrl}/api/CustomConfiguration`, cc, this.defaultHttpParams);
    }
}

export interface CustomConfiguration {
    key: string;
    value: string;
}
