import { Component, OnInit, Input } from '@angular/core';
import { HiramMaterialHMISValueItem } from 'src/app/core/model/hiram-materialHMIS-value-item';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { tap } from 'rxjs/operators';
import { MaterialHMIS } from 'src/app/core/model/materialHMIS';
import { MaterialHmisService } from 'src/app/core/services/material-hmis/material-hmis.service';
import { MaterialSummary } from 'src/app/core/model/material-summary';
import { formatNumber } from '@angular/common';
import { HfrCutPoints } from 'src/app/core/model/hfr-cut-points';
import { HfrCutPointsService } from 'src/app/core/services/hfr-cut-points.service';

@Component({
    selector: 'hiram-sbra-chemical-hazard-profile',
    templateUrl: './sbra-chemical-hazard-profile.component.html',
    styleUrls: ['./sbra-chemical-hazard-profile.component.scss'],
})
export class SbraChemicalHazardProfileComponent implements OnInit {
    constructor(
        private hiramService: HiramService,
        private materialService: MaterialHmisService,
        private hfrCutPointsService: HfrCutPointsService
    ) {}

    @Input() hiramId: number;
    items: HiramMaterialHMISValueItem[];
    summary: MaterialSummary;

    loading = false;
    health = null;
    flammability = null;
    reactivity = null;
    numberOfChemicalsWithHFRGreaterThan2 = null;
    hfrCutPoints: HfrCutPoints;

    ngOnInit() {
        this.loadMaterialDataForHiram();
        this.loadSummary();
        this.hfrCutPointsService.get().subscribe(data => (this.hfrCutPoints = data));
    }

    phsDescriptions(item: MaterialHMIS) {
        const result = [];

        this.addPhsIfNew(result, item.phs);
        this.addPhsIfNew(result, item.categoryOnePhs);
        this.addPhsIfNew(result, item.categoryTwoPhs);
        this.addPhsIfNew(result, item.categoryThreePhs);

        return result;
    }

    private addPhsIfNew(items: string[], value: number) {
        if (value) {
            this.addIfNew(items, this.materialService.phsLongName(value));
        }
    }

    private addIfNew(items: string[], value: string) {
        if (items.indexOf(value) === -1) {
            items.push(value);
        }
    }

    particularlyHazardousSubstances() {
        return this.items.filter(
            each =>
                (each.phs !== undefined && each.phs !== null) ||
                (each.categoryOnePhs !== undefined && each.categoryOnePhs !== null) ||
                (each.categoryTwoPhs !== undefined && each.categoryTwoPhs !== null) ||
                (each.categoryThreePhs !== undefined && each.categoryThreePhs !== null)
        );
    }

    chronicToxinsItems() {
        return this.items.filter(each => each.chronicToxicity);
    }

    healthQuintile() {
        return this.quintileDescription(summary => summary.healthQuintile);
    }

    flammabilityQuintile() {
        return this.quintileDescription(summary => summary.flammabilityQuintile);
    }

    reactivityQuintile() {
        return this.quintileDescription(summary => summary.reactivityQuintile);
    }

    tooltipForAvgValue(value: number) {
        return `Average for other HIRAMs is ${formatNumber(value, 'en-US', '0.0-1')}`;
    }

    loadMaterialDataForHiram() {
        this.loading = true;

        const getMaterial$ = this.hiramService.getMaterialHMISItems(this.hiramId);

        if (getMaterial$) {
            getMaterial$.pipe(tap(() => (this.loading = false))).subscribe(items => {
                this.items = items;
                this.health = items.reduce((sum: number, item) => sum + item.hCalc, 0);
                this.flammability = items.reduce((sum: number, item) => sum + item.fCalc, 0);
                this.reactivity = items.reduce((sum: number, item) => sum + item.rCalc, 0);
            });
        }
    }

    loadSummary() {
        const summary$ = this.hiramService.materialSummary(this.hiramId);
        if (summary$) {
            summary$.subscribe(data => (this.summary = data));
        }
    }

    quintileBadgeClass(quintile: number) {
        if (quintile === 1) {
            return 'bg-danger';
        }

        if (quintile === 2) {
            return 'bg-warning';
        }
    }

    requiresSOCEndorsement(value: number, type: 'health' | 'flammability' | 'reactivity') {
        return this.hfrCutPoints ? value >= this.hfrCutPoints[`${type}CutPoint2to3`] : false;
    }

    requiresPeerEndorsement(value: number, type: 'health' | 'flammability' | 'reactivity') {
        return this.hfrCutPoints ? value >= this.hfrCutPoints[`${type}CutPoint3to4`] : false;
    }

    quintileDescription(valueFunc: (x: MaterialSummary) => number): string {
        if (!this.summary) {
            return null;
        }

        switch (valueFunc(this.summary)) {
            case 1:
                return 'Top quintile';
            case 2:
                return 'Second quintile';
            case 3:
                return 'Middle quintile';
            case 4:
                return 'Fourth quintile';
            case 5:
                return 'Lowest quintile';
        }

        return `${valueFunc(this.summary)}`;
    }
}
